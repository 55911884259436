<div [ngClass]="{'selected': userService.componentSelected === ComponentTypeSelectedEnum.CONTROLLER}"
     class="controller-infos">
  <div [style.background-color]="controller.socket.connected ? 'green':'red'"
       class="state mat-elevation-z8"></div>
  <button (click)="onContextMenu($event)" class="right-button" mat-button></button>
  <button (contextmenu)="onContextMenu($event)"
          class="select-controller"
          mat-button
          routerLink="controller/{{controller.id}}">
    {{ controller.name }}
  </button>
  <div class="bottom-left">
    {{ infosCountBot }}
  </div>
  <div class="bottom-right">
    {{ controller.type }}
  </div>
</div>

<div (contextmenu)="onContextMenu($event)" class="bots-content">
  <app-team-solo-element [controller]="controller"></app-team-solo-element>
  <div (cdkDropListDropped)="dropTeam($event)" cdkDropList class="example-list">
    <div *ngFor="let team of  teams" cdkDrag>
      <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
      <app-team-element [team]="team"></app-team-element>
    </div>
  </div>
</div>
<!--<app-team-element-->
<!--  *ngFor="let team of teams"-->
<!--  [team]="team">-->
<!--</app-team-element>-->

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu>
  <ng-template let-item="item" matMenuContent>
    <button (click)="connectBotsDisconnected()" mat-menu-item>Connect Bots Disconnected</button>
    <button (click)="disconnectBots()" mat-menu-item>Disconnect Bots</button>
    <mat-divider></mat-divider>
    <button (click)="createAllBots()" mat-menu-item>Create All Bots</button>
    <button (click)="copyIds()" mat-menu-item>Copies IDs</button>
    <button (click)="addProxies()" mat-menu-item>Add Proxies</button>
    <mat-divider></mat-divider>
    <button (click)="removeAllBots()" class="action-dangerous" mat-menu-item>Remove All bots</button>
    <mat-divider></mat-divider>
    <button (click)="addBot()" mat-menu-item>Add bot</button>
    <button (click)="addBotsFromText()" mat-menu-item> Add Bots from text</button>
    <button (click)="addTeam()" mat-menu-item>Add team</button>
    <button (click)="rebuildTeam()" mat-menu-item>Rebuild Team 7</button>
    <button (click)="fillAccounts()" mat-menu-item>Fill Accounts</button>
    <button (click)="fillOldAccounts()" mat-menu-item>Fill Old Accounts</button>
    <button (click)="fillAccountsFromText()" mat-menu-item>Fill Accounts From Text</button>
    <button (click)="exportAccounts()" mat-menu-item>Export Accounts</button>
    <button (click)="generateTeam()" mat-menu-item>Generate Team</button>
    <button (click)="removeBans()" mat-menu-item>Remove Bans</button>
    <button (click)="restart()" mat-menu-item>Restart</button>
    <button (click)="generateTDM()" mat-menu-item>Generate Team</button>
  </ng-template>
</mat-menu>
